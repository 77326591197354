import axios from "axios";

/**
 * 根据环境变量区分接口默认地址
 */
switch (process.env.NODE_ENV) {
    case "production":
        // axios.defaults.baseURL = 'http://www.hyyp.cc/api.php'; //生产环境
        // axios.defaults.baseURL = 'http://www.tczg.xyz/api/'; //生产环境
        axios.defaults.baseURL = 'https://lql.hlzmz.cn/api/';

        break;
    default:
        // http://192.168.1.6:8086/api
        // axios.defaults.baseURL = 'http://www.hyyp.cc/api.php'; // 默认测试环境
        // axios.defaults.baseURL = '	http://www.mingpinhui.cc/api/'; //生产环境
        // axios.defaults.baseURL = 'http://www.tczg.xyz/api/';
        axios.defaults.baseURL = 'https://lql.hlzmz.cn/api/';
        break;
}

/**
 * 设置超时时间和跨域是否携带凭证
 */
axios.defaults.timeout = 10000; // 10s
axios.defaults.withCredentials = true; // 是否允许跨域

/**
 * 设置请求传递数据的格式
 * x-www-form-urlencoded
 */
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['Content-Type'] = 'application/json';
// axios.defaults.transformRequest = data => qs.stringify(data); 

/**
 * 设置请求拦截器
 * TOKEN校验（JWT）
 */
axios.interceptors.request.use(config => {
    // console.log(config,'******************')
    // 携带上token
    if (window.location.href.includes('token=')) {
        let href = window.location.href
        config.headers.token = href.substr(href.indexOf('token=') + 6)
        config.headers.tag = 'pc'
    } else {
        let token = '';
        if (localStorage.getItem("token") == null) {
            token = ''
            config.headers.token = token
            config.headers.tag = 'pc'
        } else {
            config.headers.token = localStorage.getItem('token')
            config.headers.tag = 'pc'
        }
    }
    // config.headers.token = '361046fd-1228-4bea-b028-1007938464ec
    return config;
}, error => {
    return Promise.reject(error);
});

/**
 * 响应拦截器
 */
/**
 * 状态设置
 */
// axios.defaults.validateStatus = status => {
//     return /^(2|3)\d{2}$/.test(status);
// }
axios.interceptors.response.use(response => {
    // console.log(response, '查看返回status')
    // console.log(response.data)
    if (response.status == 200) {
        return response.data
    } else {
        alert(response.data.msg)
    }
}, error => {
    let {
        response
    } = error;
    if (response) {
        console.log(response)
        // 服务器最起码返回结果了
        switch (response.status) {
            case 401: // 当前请求需要验证（权限）
                // console.log("当前请求需要验证")
                localStorage.clear()
                alert(response.data.msg)
                break;
            case 403: // 服务器接到请求，但是拒绝执行（token过期）
                // console.log("服务器接到请求，但是拒绝执行")
                alert(response.data.msg)
                break;
            case 404: // 找不到地址
                console.log("未找到请求地址")
                // alert("未找到请求地址")
                break;
            default:
                alert(response.statusText)
                break;
        }
    } else {
        // 服务器没有返回结果
        if (!window.navigator.onLine) {
            // 断网处理： 跳转到断网页面
            // console.log("断网了")
            alert("网络连接异常")
        }
        return Promise.reject(error);
    }
});

export default axios;