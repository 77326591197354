var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-width":"1400px"}},[_c('div',{staticClass:"head fr-c"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.imgurl+_vm.logo}}),_c('div',{staticClass:"mynav"},[(_vm.navList.length)?_c('ul',{staticClass:"fr-s-c"},[_c('li',{staticClass:"relative fr-c pointer"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t('m.Home')))])],1),_vm._l((_vm.navList),function(item,index){return _c('li',{key:index,staticClass:"relative navli"},[_c('router-link',{staticStyle:{"display":"block"},attrs:{"to":{
						path:item.type==null?'/':item.type=='table'?'/latestbroadcast':item.type=='list'?'/salesdepartmentukbranch':item.type=='page'?'/about':'/qualification',
						query:{
							pname:item.name,
							pid:item.id
						}
					}}},[_vm._v(_vm._s(item.name))]),(item.child.length)?_c('ul',{staticClass:"w-1 child-box"},[_c('li',_vm._l((item.child),function(items,indexs){return _c('router-link',{key:indexs,staticClass:"fr-c w-1",attrs:{"to":{
								path:items.type==null?'/':items.type=='table'?'/latestbroadcast':items.type=='list'?'/salesdepartmentukbranch':items.type=='page'?'/about':'/qualification',
								query:{
									pname:items.name,
									pid:items.id
								}
							}}},[_vm._v(_vm._s(items.name))])}),1)]):_vm._e()],1)}),_c('li',{staticClass:"relative fr-c"},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v(_vm._s(_vm.$t('m.contactus')))])],1)],2):_vm._e()])]),(_vm.$route.path.indexOf('/salesdepartmentukbranch')<0)?_c('el-carousel',{attrs:{"interval":5000,"arrow":"always","indicator-position":"none"}},_vm._l((_vm.banners),function(item){return _c('el-carousel-item',{key:item},[_c('img',{staticClass:"w-1 h-1 cover",attrs:{"src":_vm.imgurl+item}})])}),1):_vm._e(),_c('div',{staticClass:"new-notes fr-s-c"},[_c('img',{staticClass:"notice-square",attrs:{"src":require("../assets/imgs/home/notict_square.png")}}),_c('strong',{staticClass:"notice-title"},[_vm._v(_vm._s(_vm.$t('m.Latestannouncement'))+":")]),(_vm.announ!=null)?_c('span',{staticClass:"title-right pointer flex-1 text-line-2",on:{"click":_vm.toUK}},[_vm._v(_vm._s(_vm.announ[0].title_name))]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }