<template>
	<div style="min-width: 1400px;">
		<div class="head fr-c">
			<img :src="imgurl+logo" class="logo">
			<div class="mynav">
				<ul class="fr-s-c" v-if="navList.length">
					<li class="relative fr-c pointer">
						<router-link to="/">{{$t('m.Home')}}</router-link>
					</li>
					<li class="relative navli" v-for="(item,index) in navList" :key="index">
						<router-link style="display: block;" :to="{
							path:item.type==null?'/':item.type=='table'?'/latestbroadcast':item.type=='list'?'/salesdepartmentukbranch':item.type=='page'?'/about':'/qualification',
							query:{
								pname:item.name,
								pid:item.id
							}
						}">{{item.name}}</router-link>
						<ul class="w-1 child-box" v-if="item.child.length">
							<li>
								<router-link
								class="fr-c w-1" 
								v-for="(items,indexs) in item.child" 
								:key="indexs"
								:to="{
									path:items.type==null?'/':items.type=='table'?'/latestbroadcast':items.type=='list'?'/salesdepartmentukbranch':items.type=='page'?'/about':'/qualification',
									query:{
										pname:items.name,
										pid:items.id
									}
								}">{{items.name}}</router-link>
							</li>
						</ul>
					</li>
					<li class="relative fr-c">
						<router-link to="/contact">{{$t('m.contactus')}}</router-link>
					</li>
				</ul>
			</div>
     
			<!-- <div class="fl-c" style="margin-left: 10px;">
				<div :class="['fr-c','lg-btn','pointer','zh-btn',currentlg=='zh'?'lg-act':'']" @click="changeLg('zh')">中文</div>
				<div :class="['fr-c','lg-btn','pointer','en-btn',currentlg!='zh'?'lg-act':'']" @click="changeLg('en')">English</div>
			</div> -->
		</div>
		<!-- 轮播图 -->
		<el-carousel v-if="$route.path.indexOf('/salesdepartmentukbranch')<0" :interval="5000" arrow="always" indicator-position="none">
			<el-carousel-item v-for="item in banners" :key="item">
				<img :src="imgurl+item" class="w-1 h-1 cover">
			</el-carousel-item>
		</el-carousel>
		<!-- <img v-if="($route.path=='/salesdepartmentukbranch' || $route.path=='/salesdepartmentukbranch/ukbranddetail') && navList.length" class="h-bannerimg" :src="navList[1].url_image"> -->
		<!-- 最新公告 -->
		<div class="new-notes fr-s-c">
			<img src="../assets/imgs/home/notict_square.png" class="notice-square">
			<strong class="notice-title">{{$t('m.Latestannouncement')}}:</strong>
			<span class="title-right pointer flex-1 text-line-2" @click="toUK" v-if="announ!=null">{{announ[0].title_name}}</span>
		</div>
	</div>
</template>

<script>
import url from "../request/base";
	export default {
		name: 'Header',
		props: {
			msg: String
		},
		data() {
			return {
				banners: [],
				currentlg:'zh',   // zh 中文 en 英文
				logo:'',
				navList:[],
				notice:'',
				announ:null,
				imgurl: url.baseUrlimg,
			}
		},
		created(){
			if(localStorage.lang){
				this.currentlg = this.$i18n.locale = localStorage.lang
			}else{
				this.currentlg = this.$i18n.locale = 'zh'
				localStorage.lang = 'zh'
			}
			this.getAbout()
			this.getBanner()
			this.getnavList()
		},
		methods:{
			// 轮播图
			getBanner(){
				this.$api.home.getBanner({}).then(res=>{
					console.log(res)
					if(res.data.length){
						res.data.map(i=>{
							this.banners.push(i.image)
						})
					}
				})
			},
			// 相关信息
			getAbout(){
				this.$api.home.getAbout({}).then(res=>{
					this.logo = res.data.config[9].value
					// this.notice = res.data.config[8].value
					this.announ = res.data.announ
					console.log(this.logo,this.notice)
				})
			},
			getnavList(){
				this.$api.home.getnavList({}).then(res=>{
					console.log(res,'++++++++')
					let titles = res.data.category.filter(item=>item.pid==0)
					let children = res.data.category.filter(item=>item.pid!=0)
					titles.map(item=>{
						item.child = []
						children.map(i=>{
							if(item.id == i.pid){
								item.child.push(i)
							}
						})
					})
					this.navList = titles
					
					console.log(titles)
					// console.log(children)
				})
			},
			// 切换语言
			changeLg(lg){
				this.currentlg = this.$i18n.locale = lg
				localStorage.lang = lg
				this.$router.go(0)
			},
			toUK(){
				this.$router.push({
					path:'/salesdepartmentukbranch',
					query:{
						pname:this.navList[1].name,
						pid:this.navList[1].id
					}
					
				})
			}
		}
	}
</script>

<style scoped>
	ol,ul,li{
		list-style: none;
	}
	.head {
		height: 100px;
    min-width: 1400px;
		background-color: #FFFFFF;
	}

	.logo {
		width: 480px;
		height: 100px;
	}
    .mynav{
        margin-left: 30px;
    }
	.mynav>ul>li {
		/* height: 30px; */
		padding: 10px 18px;
		color: #333333;
		text-align: center;
	}

	.mynav>ul>li>a {
		color: #333333;
        font-size: 16px;
	}

	.mynav>ul>li>div {
		display: none;
	}

	.mynav>ul>li:hover {
		background: #94C019 ;
		color: #FFFFFF;
		font-size: 16px;
	}

	.mynav>ul>li:hover .child-box {
		display: block;
	}

	.mynav>ul>li:hover>a {
		color: #FFFFFF;
	}

	>>>.el-carousel__container {
        min-width: 1400px;
		height: 700px !important;
	}

	.el-carousel {
        min-width: 1400px;
		height: 700px;
	}

	.el-carousel__item {
        min-width: 1400px;
		height: 700px;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.new-notes {
		width: 1200px;
		margin: auto;
		padding: 30px 0px;
	}

	.notice-square {
		width: 45px;
		height: 40px;
		margin-right: 5px;
	}

	.notice-title {
		font-size: 24px;
		color: #94C019;
	}

	.title-right {
		color: #222222;
		font-size: 16px;
		margin-left: 30px;
	}

	.child-box {
		display: none;
		position: absolute;
		left: 0;
		z-index: 9;
		margin-top: 5px;
	}
	.child-box a{
		text-align: center;
		padding: 10px 8px;
		background-color: #fff;
		color: #000;
        font-size: 14px;
	}
    .child-box a:hover{
		background-color: #94C019;
		color: #fff;
	}
	.lg-btn{
	
		padding: 2px 10px;
		border-radius: 15px;
		border: 1px solid #94C019;
		color: #94C019;
		font-size: 16px;
	}
	
	.zh-btn{
		width: 54px;
		height: 27px;
	}
	.en-btn{
		width: 76px;
		height: 27px;
		margin-top: 5px;
	}
	.lg-act{
		background-color: #94C019;
		color: #ffffff;
	}
	.h-bannerimg{
    width: 100%;
		height: 700px;
	}
</style>
